import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const ShippingTerms = () => {
  return (
    <Layout>
      <SEO
        title="Shipping Terms"
        description="Shipping terms"
        meta={[
          {
            name: 'keywords',
            content:
              'Nail salon design,Nail salon startup cost,Pedicure spa Massage chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa Massage chairs for sale,Cheap spa pedicure chairs,pedicure spa Massage chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
          }
        ]}
      />

      <div className="container py-4">
        <div className="row py-5">
          <div className="col">
            <h1 className="w-100 text-center">SHIPPING TERMS</h1>
          </div>
        </div>

        <div className="row flex-column py-2 mb-2">
          <div className="col">
            <p>
              This section supplements the Purchase Terms and Conditions of CONTÉGO Spa Designs,
              Inc. (“Contego”, “we”, “us” or “our”) (displayed at{' '}
              <a
                href="https://www.contegospa.com/purchasetermsandconditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                contegospa.com/purchasetermsandconditions
              </a>{' '}
              and incorporated herein) and sets forth the terms and conditions that apply if you
              purchased our pedicure spa chair(s) and we use a third-party freight company
              (“Shipper”) to deliver them.
            </p>

            <p>
              We will arrange for a Shipper to deliver your purchased pedicure spa chair(s)
              (“Product”) on the agreed-to Requested Ship Date to the salon at Shipping address (the
              “Premises”) stated on your purchase order. We will instruct the Shipper to leave the
              Product on wooden pallets on the curb at the Premises. You or a person you engage to
              do so (the “Technician”), must connect them to water and electricity inside the
              Premises at the location you specify (the “Hook-Up Site”) in compliance with [user
              manual] when, and not before, the Premises and Hook-Up Site are Hook-Up and Test
              Ready. Hook-Up and Test Ready means:
            </p>

            <ol>
              <li>
                <p>You or your representative are present at the Premises;</p>
              </li>
              <li>
                <p>
                  All construction, plumbing and electrical work at the Premises and Connection Site
                  that is required to connect and test the Product is complete and complies with all
                  applicable local building codes and Product specifications. This means the
                  Connection Site for each spa chair includes, without limitation, the hot and cold
                  water pipes, waste drainage openings and electrical outlets comply with the
                  specifications contained in the Product’s operating manual (which is accessible
                  via{' '}
                  <a
                    href="https://www.contegospa.com/manuals-and-downloads"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.contegospa.com/manuals-and-downloads
                  </a>
                  ) (collectively, “Product Specifications”);{' '}
                </p>
              </li>
            </ol>

            <h3>TESTING</h3>

            <p>
              When the Technician completes the hook-up service, the Technician must test your
              Product to make certain, and confirm, that the plumbing and electrical are working in
              compliance with the appropriate Product Specifications.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ShippingTerms
